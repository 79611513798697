import React from "react";
// import Carousel from "react-elastic-carousel";
import { Card, Carousel } from "react-bootstrap";
import sixer01 from "../../assets/images/6ixer-12/6ixer01.jpg";
import sixer02 from "../../assets/images/6ixer-12/6ixer02.jpg";
import sixer03 from "../../assets/images/6ixer-12/6ixer03.jpg";
import sixer04 from "../../assets/images/6ixer-12/6ixer04.jpg";
import sixer05 from "../../assets/images/6ixer-12/6ixer05.jpg";
import sixer06 from "../../assets/images/6ixer-12/6ixer06.jpg";
import sixer07 from "../../assets/images/6ixer-12/6ixer07.jpg";
import sixer08 from "../../assets/images/6ixer-12/6ixer08.jpg";
import sixer09 from "../../assets/images/6ixer-12/6ixer09.jpg";
import sixer10 from "../../assets/images/6ixer-12/6ixer10.jpg";
import sixer11 from "../../assets/images/6ixer-12/6ixer11.jpg";
// import sixer12 from "../../assets/images/6ixer-12/6ixer12.jpg";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
const Presentation = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 1024, itemsToShow: 3 },
    { width: 1440, itemsToShow: 4 },
  ];
  return (
    <>
      <div
        className="container presenatation_wrapper carousel slide"
        id="PresentationId"
        data-bs-ride="carousel"
      >
        <div data-aos="fade-down" data-aos-once="true">
          <h1 className="pre-title mb-5">Presentation</h1>
        </div>

        <div className="module-border-wrap">
          <Carousel className="caraousel_wrapper">
            <Carousel.Item>
              <img className="d-block w-100" src={sixer01} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer02} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer03} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer04} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer05} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer06} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer07} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer08} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer09} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer10} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={sixer11} width="100%" />
            </Carousel.Item>

            {/* <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={sixer12} width="100%"
                      />
                    </Carousel.Item> */}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Presentation;

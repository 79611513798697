import { Modal, Button, Row, Col } from "react-bootstrap";
import React from "react";
import MetaMaskIcon from "../../../assets/images/metamaskicon.svg";
import WalletConnect from "../../../assets/images/walletconnecticon.svg";
import CoinBase from "../../../assets/images/coinbaseicon.svg";
import { useWeb3React } from '@web3-react/core';
import { injected, walletconnect, resetWalletConnector, walletlink } from "../../Connector/Connectors";
import GreenIcon from "../../../assets/images/greenicon.svg";
const ConnectModal = ({ show, handleClose }) => {
  const { activate , active} = useWeb3React();
  const connectMetamask = async () => {
    // debugger;
    try {
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  }
  const connectWallectconnect = async () => {
    try {
      resetWalletConnector(walletconnect);
      await activate(walletconnect);
      console.log("jgdajsh")
    } catch (ex) {
      console.log(ex);
    }
  }
  
  const connectCoinbase = async () => {
    try {
      await activate(walletlink);
    } catch (ex) {
      console.log(ex);
    }
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="px-5 connect_modal_wrapper">
            <h4 className="mb-4">Connect Your Wallet</h4>
            <Row className="mb-3">
              <Button onClick={connectMetamask}>
                <Row>
                  <Col lg={4} md={4} sm={4} xs={5} className="title" >
                  {/* {active ? <div className="mr-1"><img src={GreenIcon}/></div> : ""}   */}
                  Meta Mask
                  </Col>
                  <Col lg={{ span: 3, offset: 5 }} md={{ span: 5, offset: 3 }} sm={{ span: 5, offset: 3 }} xs={{ span: 5, offset: 2 }}>
                    <img src={MetaMaskIcon} />
                  </Col>
                </Row>
              </Button>
            </Row>
            <Row className="mb-3">
              <Button onClick={connectWallectconnect}>
                <Row>
                  <Col lg={5} md={5} sm={5} xs={6} className="title" >
                    Wallet Connect
                  </Col>
                  <Col lg={{ span: 3, offset: 4 }} md={{ span: 5, offset: 2 }} sm={{ span: 5, offset: 2 }} xs={{ span: 5, offset: 1 }}>
                    <img src={WalletConnect} />
                  </Col>
                </Row>
              </Button>
            </Row>
            <Row className="mb-3">
              <Button onClick={connectCoinbase}>
                <Row>
                  <Col lg={5} md={5} sm={5} xs={6} className="title">
                    Coinbase Wallet
                  </Col>
                  <Col lg={{ span: 3, offset: 4 }} md={{ span: 5, offset: 2 }} sm={{ span: 5, offset: 2 }} xs={{ span: 5, offset: 1 }}>
                    <img src={CoinBase} />
                  </Col>
                </Row>
              </Button>
            </Row>
          </div>

        </Modal.Body>
      </Modal>
    </>
    //   <Modal
    //     title="Add Distributer"
    //     visible={visible}
    //     onCancel={onCancel}
    //   >
    //         <div>
    //         <Row gutter={[16,16]}>
    //             <Col lg={24}>
    //             <Input />
    //             </Col>
    //             <Col lg={24}>
    //             <Button>Generate Distributor</Button>
    //             </Col>
    //             <Col lg={24}>
    //             <Typography>Add Coin symbol</Typography>
    //             <Input/>
    //             </Col>
    //           </Row>          
    //         </div>
    //   </Modal>
  );
}
export default ConnectModal;

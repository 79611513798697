import React, { useState } from "react";
import Logo from "../../assets/images/Logo.svg";
import ConnectModal from "../_common/Modal/ConnectModal";
import {
  Link,
  useLocation,
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import SeacrchIcon from "../../assets/images/searchicon.svg";
import WalletIcon from "../../assets/images/walleticon.svg";
import PresaleTokenIcon from "../../assets/images/presaletoken.svg";
// import { Link } from 'react-scroll';
// import { useNavigate } from 'react-router';
const Header = () => {
  // let navigate = useNavigate();
  const [openmodal, setOpenModal] = useState(false);
  const handleConnect = () => {
    setOpenModal(true);
  };
  const modalClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <nav class="navbar navbar-expand-lg  navbar-light header_wrapper">
        <div class="container">
          <a class="navbar-brand" href="#">
            <img src={Logo} alt="logo" width="68px" height="68" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse mid-content"
            id="navbarSupportedContent"
          >
            <div className=" justify-conten-flex-end" style={{ width: "100%" }}>
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-5 ul-content">
                {/* <li>
<div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1"><img src={SeacrchIcon} width="30px" /></span>
  <input type="text" class="form-control input_icon" placeholder="e.g  Web Design, UX/UI Design, App Development" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
<input type="text" class="form-control input_icon" placeholder="e.g  Web Design, UX/UI Design, App Development" width="100%"/>
</li> */}
                <Link
                  to="#AboutId"
                  style={{ textDecoration: "none" }}
                  smooth={true}
                  onClick={() => window.location.replace("/#AboutId")}
                >
                  <li class="nav-item  px-3 li-content">
                    <a class="nav-link  nav-content">About</a>
                  </li>
                </Link>

                <Link
                  to="#RoadmapId"
                  style={{ textDecoration: "none" }}
                  onClick={() => window.location.replace("/#RoadmapId")}
                >
                  <li class="nav-item  px-3 li-content">
                    <a class="nav-link  nav-content">Roadmap</a>
                  </li>
                </Link>

                <Link
                  to="#PresentationId"
                  style={{ textDecoration: "none" }}
                  smooth
                  onClick={() => window.location.replace("/#PresentationId")}
                >
                  <li class="nav-item px-3 li-content">
                    <a class="nav-link  nav-content">Presentation</a>
                  </li>
                </Link>
                {/* <li class="nav-item px-3 li-content">
          <div style={{borderRadius:"50px"}}>
          <button className="signup ">Sign Up</button>
          </div>
        </li> */}
                <li class="nav-item px-3 li-content">
                  <a href="https://yashadao.io/yashapad" target="_blank">
                    <button
                      // onClick={handleConnect}
                      className=" connect-btn "
                    >
                      <img
                        src={PresaleTokenIcon}
                        width="20px"
                        className="walleticon"
                      />
                      PRESALE LINK
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <ConnectModal show={openmodal} handleClose={modalClose} />
    </>
  );
};

export default Header;

// import React from "react";
// const Footer = () => {
//   return (
//     <>
//       <div className="footer_wrapper">
//         <h4 className="private d-flex justify-content-center py-3">
//           @2022 6ixer private limited. All Right Reserved
//         </h4>
//       </div>
//     </>
//   );
// };
// export default Footer;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../../assets/images/Logo.svg";
import TwitterIcon from "../../assets/images/twittericon.svg";
import TelegramIcon from "../../assets/images/telegram.svg";
import MailIcon from "../../assets/images/mailicon.svg";
const Footer = () => {
  return (
    <>
      <div className="footer_wrapper  container-fluid mt-4">
        <div className="container p-5 inner_footer">
          <Row>
            <Col lg={4} md={4} sm={12} xs={12}>
              <img src={Logo} width="30%" />
              <p className="mt-4 mb-4 para">
                A visionary vast crypto project. More than just a token. A
                community laying the foundations to make history.
              </p>
              <div className="social-icons">
                <span className="telgramicon mr-3">
                  <a href="https://t.me/EntryPortal6ixer" target="_blank">
                    <img src={TelegramIcon} width="5%" />
                  </a>
                </span>
                <span className="telgramicon">
                  <a
                    href="https://mobile.twitter.com/Official6ixer"
                    target="_blank"
                  >
                    <img src={TwitterIcon} width="5%" />
                  </a>
                </span>
              </div>
            </Col>
            <Col
              lg={{ span: 6, offset: 2 }}
              md={{ span: 6, offset: 2 }}
              sm={12}
              xs={12}
            >
              <Row>
                <Col lg={4} md={5} sm={12} xs={12}>
                  <h1 className="title">Website</h1>
                  <ul>
                    <li onClick={() => window.location.replace("/#AboutId")}>
                      About
                    </li>
                    <li onClick={() => window.location.replace("/#RoadmapId")}>
                      Roadmap
                    </li>
                    <li
                      onClick={() =>
                        window.location.replace("/#PresentationId")
                      }
                    >
                      Presentation
                    </li>

                    <li>
                      <a href="https://yashadao.io/yashapad" target="_blank">
                        Presale Link
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col lg={1} md={1} sm={12} xs={12}></Col>
                <Col lg={{ span: 5, offset: 2 }} md={6} sm={12} xs={12}>
                  <h1 className="title">Contact Info</h1>
                  <ul>
                    <li>
                      <a href="https://t.me/EntryPortal6ixer" target="_blank">
                        <img src={TelegramIcon} width="20px" /> @jaxets
                      </a>{" "}
                    </li>
                    <li>
                      <a
                        href="https://mobile.twitter.com/Official6ixer"
                        target="_blank"
                      >
                        <img src={TwitterIcon} width="20px" /> @Official6ixer
                      </a>
                    </li>
                    {/* <li>
                      <a href="mailto:info@6ixer.com">
                        <img src={MailIcon} width="20px" /> info@6ixer.com
                      </a>
                    </li> */}
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <h4 className="private d-flex justify-content-center">
          @2022 6ixer private limited. All Right Reserved
        </h4>
      </div>
    </>
  );
};
export default Footer;

import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

// const RPC_URLS = {
// 	1: 'https://mainnet.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4',
// 	4: 'https://rinkeby.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4'
// };
const INFURA_KEY = "3dfb5f4a4b564ee893955cd379a3e449";
export const RPC_URLS = {
  1: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  4: process.env.RPC_URL_4,
};

//metamask
export const injected = new InjectedConnector({
	supportedChainIds: [ 1, 3, 4, 5, 42 ]
});

//walletconnect
export const walletconnect = new WalletConnectConnector({
	infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
	qrcode: true,
  });
  
  //  Enable session (triggers QR Code modal)
//   await walletconnect.enable();
// export const walletconnect = new WalletConnectConnector({
// 	infuraId: "3cd774e14cf34ff78167908f8377051c" ,
// 	qrcode: true,
// });

   
// export const walletconnect = new WalletConnectConnector({
// 	rpc: { 1: RPC_URLS[1], 4: RPC_URLS[4] },
// 	bridge: "https://bridge.walletconnect.org",
// 	qrcode: true,
// 	pollingInterval: 15000,
//   });

export function resetWalletConnector(connector) {
	if (connector && connector instanceof WalletConnectConnector) {
		connector.walletConnectProvider = undefined;
	}
}

// coinbase
// export const walletlink = new WalletLinkConnector({
// 	url: RPC_URLS[4],
// 	appName: 'demo-app',
// 	supportedChainIds: [ 1, 4 ]
// });

export const walletlink = new WalletLinkConnector({
	url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
	// appName: "sixer-website",
	supportedChainIds: [1, 3, 4, 5, 42],
   });
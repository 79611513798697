import React from 'react';
import {Row, Col, Container,InputGroup,DropdownButton ,Dropdown,FormControl, Button,Card} from "react-bootstrap";
const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <>
    <Card className='mt-2'>
    <div className='timer'>
      <p className='mt-3 value'>{value}</p>
    </div>
    </Card>
     <div>
      <span className='type'>{type}</span>
    </div>
    </>
   
  );
};

export default DateTimeDisplay;

import React from "react";
import { Row, Col } from "react-bootstrap";
import RoadMapIcon from "../../assets/images/roadmap.svg";
import Group01 from "../../assets/images/group01.svg";
import Group02 from "../../assets/images/group02.svg";
import Group03 from "../../assets/images/group03.svg";
import Group04 from "../../assets/images/group04.svg";
import Group05 from "../../assets/images/group05.svg";
import Group06 from "../../assets/images/group06.svg";
import Group07 from "../../assets/images/group07.svg";
import Group08 from "../../assets/images/group08.svg";
import Group09 from "../../assets/images/group09.svg";
import Group10 from "../../assets/images/group10.svg";
import LightTriangle from "../../assets/images/sixer logo 3.svg";
// import TriangleIcon from "../../assets/images/triangleicon.svg";
import ColouredTriangle from "../../assets/images/colouredtriangle.svg";
import TriangleIcon from "../../assets/images/sixerlogo1.svg";
import Arrow from "../../assets/images/Greendownward.svg";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
const RoadMap = () => {
  return (
    <>
      <div className="container-fluid roadmap_wrapper" id="RoadmapId">
        <div className="imageWrapper">
          <img src={ColouredTriangle} className="image_triangle" />
        </div>

        <div className="roadmap">
          <div className="d-flex justify-content-start ">
            <img src={ColouredTriangle} width="20%" className=" triangle" />
          </div>
          <div
            className="d-flex justify-content-center title_wrapper "
            data-aos="fade-down"
            data-aos-once="true"
          >
            <span className="titles1">Road</span>
            <span className="titles">map</span>
          </div>

          <div className="roadmap_image">
            <img src={ColouredTriangle} width="20%" className=" triangle2" />
            <img src={RoadMapIcon} width="100%" />
            <img src={LightTriangle} className="lighttriangle" />
          </div>
        </div>

        {/* /////Road Map to small screen  */}
        <div className="contentsmall_screen_wrapper">
          <Row>
            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#4b9cd3", border: "1px solid #4b9cd3" }}
              >
                <span>
                  <img src={Group01} />
                </span>{" "}
                Deploy the 6ixer Marketplace Web App (Dapp)
              </h2>
            </Col>
            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#6D6DE2", border: "1px solid #6D6DE2" }}
              >
                <span>
                  <img src={Group02} />
                </span>{" "}
                Introduce Android Mobile Platform
              </h2>
            </Col>

            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#1FBC9C", border: "1px solid #1FBC9C" }}
              >
                <span>
                  <img src={Group03} />
                </span>{" "}
                Introduce iOS Mobile Platform
              </h2>
            </Col>
            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#E2B36D", border: "1px solid #E2B36D" }}
              >
                <span>
                  <img src={Group04} />
                </span>{" "}
                Introduce new Trading Pairse.g. $USDC, $USDT, $DAI, etc
              </h2>
            </Col>
            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#BF8360", border: "1px solid #BF8360" }}
              >
                <span>
                  <img src={Group05} />
                </span>{" "}
                Introduce new currencies to pay for services (e.g. $ETH/$WBTC).
              </h2>
            </Col>

            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#D65E83", border: "1px solid #D65E83" }}
              >
                <span>
                  <img src={Group06} />
                </span>{" "}
                Deploy Self-Paying and/or Self-Regenerating Loans to pay for
                services.
              </h2>
            </Col>

            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#1FBC9C", border: "1px solid #1FBC9C" }}
              >
                <span>
                  <img src={Group07} />
                </span>{" "}
                Connect a Fiat on-ramp for the direct platform access.
              </h2>
            </Col>

            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#E2B36D", border: "1px solid #E2B36D" }}
              >
                <span>
                  <img src={Group08} />
                </span>{" "}
                Offer Pro Services
              </h2>
            </Col>

            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#BF8360", border: "1px solid #BF8360" }}
              >
                <span>
                  <img src={Group09} />
                </span>{" "}
                Offer celebrity Services famous artists & designers.
              </h2>
            </Col>
            <div className="d-flex justify-content-center mb-2 ">
              <img src={Arrow} width="15%" />
            </div>

            <Col sm={12} xs={12}>
              <h2
                className="groups"
                style={{ color: "#D65E83", border: "1px solid #D65E83" }}
              >
                <span>
                  <img src={Group10} />
                </span>{" "}
                Create a Premium Subscription Package with monthly fees
              </h2>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default RoadMap;

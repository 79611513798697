import React from "react";
import {
  Row,
  Col,
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Button,
  Card,
} from "react-bootstrap";
import LogoAnimationsgGif from "../../assets/images/LogoAnimationsgGif.gif";
import NewLogo from "../../assets/images/NewLogo.svg";
import ColouredTriangle from "../../assets/images/colouredtriangle.svg";
// import StudentIcon from "../../assets/images/studenticon.svg";
// import EducationIcon from "../../assets/images/eductionicon.svg";
import { Data } from "./Datafile";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
const BlockchainEducationSection = () => {
  return (
    <>
      <div className="blockchainsection_wrapper">
        <div className="blockchainsectionWrapper">
          <div className="leftWrapper">
            <div className="imageWrapperblockchain">
              <img
                src={ColouredTriangle}
                className="image_triangle_blockchain"
              />
            </div>
          </div>
          <div className="rightWrapper">
            <div
              className="content_wrapper"
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <h1 className="content_title">
                Promoting <span className="ideas">Blockchain </span>Education
              </h1>
              <p className="para">
                Allowing aspiring students to educate themselves on blockchain
                specific courses will make a positive long term impact on the
                cryptocurrency space as a whole.
              </p>
            </div>
          </div>
        </div>

        <div className="bottomWrapper">
          <div className="leftWrapper">
            <img src={LogoAnimationsgGif} />
          </div>
          <div className="rightside_wrapper">
            <div className="cardWrapper">
              {Data.map((data) => (
                <>
                  <div className="peraWrapper">
                    <div className="imgWrapper">
                      <img src={data.img} />
                    </div>

                    <div className="headingWrapper">
                      <h3
                        className="block_points"
                        data-aos="fade-left"
                        data-aos-once="true"
                      >
                        {data.header}
                      </h3>
                      <p
                        className="block_points_para"
                        data-aos="fade-left"
                        data-aos-once="true"
                      >
                        {data.para}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockchainEducationSection;

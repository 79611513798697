import React from "react";
import {
  Row,
  Col,
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Button,
  Card,
} from "react-bootstrap";
import Badges from "../../assets/images/badges.svg";
import Protection from "../../assets/images/protection.svg";
import RatingSystem from "../../assets/images/ratingsystem.svg";
import ColouredTriangle from "../../assets/images/colouredtriangle.svg";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
const BackgroundProcess = () => {
  return (
    <>
      <div className=" container-fluid backgroundprocess-wrapper">
        <div className="images_reviewprocess">
          <img src={ColouredTriangle} className="images_triangle_review" />
        </div>

        <div>
          <div data-aos="fade-down" data-aos-once="true">
            <h1 className="standouts mb-5">
              Background <span className="ideas">Review </span>Process
            </h1>
          </div>

          <div className="shoutout-inner">
            <Row>
              <Col lg={4} md={4} sm={12} xs={12}>
                <div className="shoutout_card">
                  <img src={Badges} className="icons mb-3" />
                  <div
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    data-aos-once="true"
                  >
                    <h2 className="standout_card_title">KYC Badges</h2>
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <h5 className="standout_card_para">
                      Some freelancers who voluntarily provide personal
                      information will have a “6ixer verified badge” on their
                      profile, which will have two tiers
                    </h5>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <div className="shoutout_card">
                  <img src={Protection} className="icons mb-3" />
                  <div
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    data-aos-once="true"
                  >
                    <h2 className="standout_card_title">Data Protection</h2>
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <h5 className="standout_card_para">
                      6ixer is dedicated to strong cybersecurity and
                      Internet-hygiene practices. All KYC data is encrypted and
                      securely stored on our servers. 6ixer will never sell any
                      Freelancer or Customer’s data.
                    </h5>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <div className="shoutout_card">
                  <img src={RatingSystem} className="icons mb-3" />
                  <div
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    data-aos-once="true"
                  >
                    <h2 className="standout_card_title">
                      Content Creator Rating System
                    </h2>
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <h5 className="standout_card_para">
                      Much like similar freelance marketplaces, 6ixer will have
                      a review and rating system implemented.
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackgroundProcess;

import "./App.css";

import SinglePageHome from "./Components/SignlePage/SinglePageHome";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { providers } from "ethers";
function App() {
  const getLibrary = (provider) => {
    return new providers.Web3Provider(provider);
  };
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <Routes>
            <Route exact path="/" element={<SinglePageHome />} />
          </Routes>
        </Router>
      </Web3ReactProvider>
    </>
  );
}

export default App;

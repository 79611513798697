import React from "react";
import {
  Row,
  Col,
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Button,
  Card,
} from "react-bootstrap";
import FundsIcon from "../../assets/images/funds.svg";
import DepositeIcon from "../../assets/images/deposite.svg";
import HireIcon from "../../assets/images/hiree.svg";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
const EscrowModule = () => {
  return (
    <>
      <div className="escrow-wrapper">
        <div data-aos="fade-down" data-aos-once="true">
          <h1 className="standouts">
            The Importance of <span className="ideas">Escrow</span>
          </h1>
        </div>
        <div
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <p className="para">
            In “trustless” systems like decentralized blockchains, trust is
            often still needed between two people. When designing 6ixer to be
            the most capable and most user-friendly platform, we created an
            Escrow system which helps to ensure safe and legitimate
            transactions, benefiting both content creators, customers, and token
            holders.{" "}
          </p>
        </div>

        <div className="escrow-inner">
          <Row>
            <Col lg={4} md={4} sm={12} xs={12} className="escrow-col">
              <div className="escrow-card">
                <div>
                  <img src={HireIcon} className="mb-4" />
                </div>

                <h2 className="escrow_card_title">User hires Freelancer</h2>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="escrow-col">
              <div className="escrow-card">
                <div>
                  <img src={DepositeIcon} className="mb-4" />
                </div>
                <h2 className="escrow_card_title">
                  User Deposits Funds in 6ixer Escrowed Smart Contract
                </h2>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="escrow-col">
              <div className="escrow-card">
                <div>
                  <img src={FundsIcon} className="mb-4" />
                </div>
                <h2 className="escrow_card_title">
                  Funds sent to Freelancer upon satisfactory approval by user
                </h2>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default EscrowModule;

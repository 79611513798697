import React from "react";
import {
  Row,
  Col,
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Button,
  Card,
} from "react-bootstrap";
import Logo from "../../assets/images/Logo.svg";
import RoadMapIcon from "../../assets/images/newroadmap.svg";
import Group01 from "../../assets/images/group01.svg";
import Group02 from "../../assets/images/group02.svg";
import Group03 from "../../assets/images/group03.svg";
import Group04 from "../../assets/images/group04.svg";
import Group05 from "../../assets/images/group05.svg";
import Group06 from "../../assets/images/group06.svg";
import Group07 from "../../assets/images/group07.svg";
import Group08 from "../../assets/images/group08.svg";
import Group09 from "../../assets/images/group09.svg";
import Group10 from "../../assets/images/group10.svg";
import LightTriangle from "../../assets/images/sixer logo 3.svg";
// import TriangleIcon from "../../assets/images/triangleicon.svg";
// import TriangleIcon from "../../assets/images/sixer logo 1.svg";
import Arrow from "../../assets/images/Greendownward.svg";
import CountdownTimer from "../_common/CountDownTimer/CountdownTimer";
import SixerPDF from "../../assets/6ixer.pdf";
import RoundingIcon from "../../assets/images/Roundinggif.gif";
import Playicon from "../../assets/images/playicon.svg";
import CryptoJobIcon from "../../assets/images/cryptojob.svg";
import SafetyFocusIcon from "../../assets/images/safetyfocus.svg";
import RewardingIcon from "../../assets/images/rewarding.svg";
// import LightTriangle from "../../assets/images/sixer logo 3.svg";
// import TriangleIcon from "../../assets/images/sixerlogo1.svg";
import ColouredTriangle from "../../assets/images/colouredtriangle.svg";
import TelegramIcon from "../../assets/images/telegram.svg";
import TwitterIcon from "../../assets/images/twittericon.svg";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
const JoinCommunity = () => {
  return (
    <>
      <div className="container-fluid communityWrapper">
        <div className="images">
          <img src={ColouredTriangle} className="images_triangle" />
        </div>
        <div className="joincommunity_wrapper">
          <Card className="joincommunity_card_wrapper px-5 py-5">
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div data-aos="fade-down" data-aos-once="true">
                  <h2 className="community_title">JOIN OUR COMMUNITY</h2>
                </div>

                <div
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-once="true"
                >
                  <p className="community_para">
                    6ixer links enterprises and entrepreneurs to decentralized
                    web development and content creation services through our
                    third-party freelancers, enabling individuals and teams to
                    create and upgrade the high-tech projects of their dreams,
                    benefiting the entire cryptocurrency and digital assets
                    community.
                  </p>
                </div>

                {/* <img src={TelegramIcon} className=" p-3" />
                <img src={TwitterIcon} className=" p-3" /> */}
              </Col>
            </Row>
            {/* <img src={LightTriangle} className="lighttriangle" width="25%" /> */}
          </Card>
        </div>
      </div>
    </>
  );
};

export default JoinCommunity;

import StudentIcon from "../../assets/images/studenticon.svg";
import EducationIcon from "../../assets/images/eductionicon.svg";
import Freelaner from "../../assets/images/newfreelancer.svg";
export const Data = [
  {
    id: 1,
    img: StudentIcon,
    header: " How 6ixer helps students",
    para: " 5% of all profits generated from the 6ixer freelance marketplace will go towards university-backed cryptocurrency and digital asset courses.",
  },
  {
    id: 2,
    img: EducationIcon,
    header: " Benefits to higher education",
    para: "  Giving access to real blockchain education will create a more knowledgeable base of investors and creators. This will allow cryptocurrency to really push the limits and could lead to groundbreaking protocols being created.",
  },
  {
    id: 3,
    img: Freelaner,
    header: " Introducing new freelancers",
    para: "  Students who enroll in courses that are paid for by 6ixer may be enticed to bring any knowledge or skills they possess to the 6ixer freelance platform.",
  },
];

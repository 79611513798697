import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./useCountdown";
import {
  Row,
  Col,
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Button,
  Card,
} from "react-bootstrap";
import TokenIcon from "../../../assets/images/tokenicon.svg";
import PresaleTokenIcon from "../../../assets/images/presaletoken.svg";
// const ExpiredNotice = () => {
//   return (
//     <div className="expired-notice">
//       <span>Expired!!!</span>
//       <p>Please select a future date and time.</p>
//     </div>
//   );
// };

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div>
      <Row>
        <Col xs={3} sm={2} md={3} lg={3}>
          <DateTimeDisplay value={days} type={"DAYS"} />
        </Col>
        <Col xs={3} sm={2} md={3} lg={3}>
          <DateTimeDisplay value={hours} type={"HOURS"} />
        </Col>
        <Col xs={3} sm={2} md={3} lg={3}>
          <DateTimeDisplay value={minutes} type={"MINUTES"} />
        </Col>
        <Col xs={3} sm={2} md={3} lg={3}>
          <DateTimeDisplay value={seconds} type={"SECONDS"} />
        </Col>
      </Row>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  return (
    <>
      <Card className="timer_card p-4">
        <Card.Body>
          <Card.Title className="title">Pre-Sale Ends In</Card.Title>
          <div className="showcounter_wrapper">
            <ShowCounter
              days={days}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
            {/* <a href="https://yashadao.io/yashapad" target="_blank">
              <Button className="connect_btn">
                <img
                  src={PresaleTokenIcon}
                  width="20px"
                  className="walleticon"
                />
                PRESALE LINK
              </Button>
            </a> */}
          </div>

          {/* <Row className="mt-5">
            <Col
              sm={4}
              xs={4}
              lg={5}
              md={5}
              className="token_name"
              style={{ textAlign: "left", marginBottom: "15px" }}
            >
              TOKEN PRICE:
            </Col>
            <Col sm={4} xs={4} lg={3} md={3} className="token_name ">
              WE ACCEPT:
            </Col>
            <Col sm={4} xs={4} lg={4} md={4} className="token_name">
              TOKEN AVAILABLE ON PRE-SALE:
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={4} md={4} lg={4} className="token_range">
              USDa =$0.05
            </Col>
            <Col xs={4} sm={4} md={4} lg={4}>
              <img src={TokenIcon} className="mid" />
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className="token_range">
              90,000,000 ETH
            </Col>
          </Row> */}
        </Card.Body>
        {/* <Row>
          <Col lg={8}>
            <Button className="buy_btn mt-5 px-5 py-2">
              Buy Tokens With 45% Off
            </Button>
          </Col>
          <Col lg={4}>
            <h5 className="purchase mt-5">MINIMUM PURCHASE:</h5>
            <h5 className="usda">10,000 USDa</h5>
          </Col>
        </Row> */}
      </Card>
    </>
  );
  // if (days + hours + minutes + seconds <= 0) {
  //   return <ExpiredNotice />;
  // } else {

  // }
};

export default CountdownTimer;

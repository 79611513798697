import React from "react";
import ColouredTriangle from "../../assets/images/colouredtriangle.svg";
import {
  Row,
  Col,
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Button,
  Card,
} from "react-bootstrap";
import Logo from "../../assets/images/Logo.svg";
import RoadMap from "./Roadmap";
import Presentation from "./Presentation";
import Footer from "../Footer/Footer";
import Header from "../Header/header";
import Presale from "./PreSale";
import JoinCommunity from "./JoinCommunity";
import CountdownTimer from "../_common/CountDownTimer/CountdownTimer";
import SixerPDF from "../../assets/6ixer.pdf";
import RoundingIcon from "../../assets/images/Roundinggif.gif";
import Playicon from "../../assets/images/playicon.svg";
import CryptoJobIcon from "../../assets/images/cryptojob.svg";
import SafetyFocusIcon from "../../assets/images/safetyfocus.svg";
import RewardingIcon from "../../assets/images/rewarding.svg";
import LightTriangle from "../../assets/images/sixer logo 3.svg";
import TriangleIcon from "../../assets/images/sixerlogo1.svg";
import EscrowModule from "./Escrow";
import BackgroundProcess from "./Backgroundprocess";
import BlockchainEducationSection from "./BlockchainEducationSection";
// import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
const Home = () => {
  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  return (
    <>
      <Header />
      {/* <div className="container-fluid main_wrapper"> */}
      <div className=" home_wrapper container py-5" id="AboutId">
        <div style={{ position: "relative" }}>
          <Row className="home_wrapper_inner">
            <Col
              xlg={{ span: 6, order: 1 }}
              lg={{ span: 6, order: 1 }}
              md={{ span: 12, order: 2 }}
              sm={{ span: 12, order: 2 }}
              xs={{ span: 12, order: 2 }}
              className="mt-5 left-col"
            >
              <div>
                <div
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-once="true"
                >
                  <h1 className="bright">
                    Bring Your
                    <span className="idea"> Dreams</span> to Life
                  </h1>
                  <h4 className=" access py-4">
                    Access global talent through the world’s first Web3
                    Freelance network to design, innovate, build, and create the
                    projects of your dreams.
                  </h4>
                </div>

                <div className="mt-5 learnmore">
                  <a href="https://t.me/EntryPortal6ixer" target="_blank">
                    <Button className="connect-btn">Join Community</Button>
                  </a>

                  <span className="learn">
                    {" "}
                    <img src={Playicon} /> Learn more about 6ixer
                  </span>
                </div>
              </div>
            </Col>
            <Col
              xlg={{ span: 6, order: 2 }}
              lg={{ span: 6, order: 2 }}
              md={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              xs={{ span: 12, order: 1 }}
              className="mobilegroup"
            >
              <img
                src={RoundingIcon}
                width="100%"
                height="100%"
                className="roundingicon"
              />
            </Col>
          </Row>
        </div>

        <div>
          <Presale />
        </div>

        <div className="shoutout-wrapper">
          <div data-aos="fade-down" data-aos-once="true">
            <h1 className="standouts mb-5">
              Why <span className="ideas">6ixer </span>is the Future of Content
              Creation
            </h1>
          </div>

          <div className="shoutout-inner">
            <Row>
              <Col lg={4} md={4} sm={12} xs={12}>
                <div className="shoutout_card">
                  <div>
                    <img src={CryptoJobIcon} className="mb-4" width="15%" />
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    data-aos-once="true"
                  >
                    <h2 className="standout_card_title">
                      Designed for Creators
                    </h2>
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <h5 className="standout_card_para">
                      6ixer empowers individuals to work in crypto and digital
                      assets remotely from their homes and enables
                      entrepreneurs, established teams, and idea creators to
                      launch, upgrade, and maintain their projects.
                    </h5>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <div className="shoutout_card">
                  <div>
                    <img src={SafetyFocusIcon} className="mb-4" width="15%" />
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    data-aos-once="true"
                  >
                    <h2 className="standout_card_title">
                      Security-Centric Architecture
                    </h2>
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <h5 className="standout_card_para">
                      Ensuring a fair, equitable, and decentralized future is
                      one of our main priorities. We’ve designed the 6ixer
                      platform with built-in safety mechanisms to provide
                      holders, content creators, and entrepreneurs with the
                      confidence they need to build the best potential projects
                    </h5>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <div className="shoutout_card">
                  <div>
                    <img src={RewardingIcon} className="mb-4" width="15%" />
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    data-aos-once="true"
                  >
                    <h2 className="standout_card_title">Rewarding Holders</h2>
                  </div>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <h5 className="standout_card_para">
                      6ixer's approach to rewarding holders is unlike no other.
                      50% of total revenue generated by the platform will be
                      returned to token holders in many different forms.
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <EscrowModule />
        </div>
        <div>
          <BackgroundProcess />
        </div>
        <div>
          <BlockchainEducationSection />
        </div>
        {/* Roadmap */}
        <div>
          <RoadMap />
        </div>

        {/* Presesnatation */}
        <div className="">
          <Presentation pdf={SixerPDF} />
        </div>
        <div>
          <JoinCommunity />
        </div>
      </div>
      {/* </div> */}

      <div>
        <Footer />
      </div>
    </>
  );
};

export default Home;

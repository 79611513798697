import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import Logo from "../../assets/images/Logo.svg";
import RoadMapIcon from "../../assets/images/newroadmap.svg";
import Group01 from "../../assets/images/group01.svg";
import Group02 from "../../assets/images/group02.svg";
import Group03 from "../../assets/images/group03.svg";
import Group04 from "../../assets/images/group04.svg";
import Group05 from "../../assets/images/group05.svg";
import Group06 from "../../assets/images/group06.svg";
import Group07 from "../../assets/images/group07.svg";
import Group08 from "../../assets/images/group08.svg";
import Group09 from "../../assets/images/group09.svg";
import Group10 from "../../assets/images/group10.svg";
import LightTriangle from "../../assets/images/sixer logo 3.svg";
// import TriangleIcon from "../../assets/images/triangleicon.svg";
// import ColouredTriangle from "../../assets/images/colouredtriangle.svg";
// import TriangleIcon from "../../assets/images/sixer logo 1.svg";
import Arrow from "../../assets/images/Greendownward.svg";
import CountdownTimer from "../_common/CountDownTimer/CountdownTimer";
import SixerPDF from "../../assets/6ixer.pdf";
import RoundingIcon from "../../assets/images/Roundinggif.gif";
import Playicon from "../../assets/images/playicon.svg";
import CryptoJobIcon from "../../assets/images/cryptojob.svg";
import SafetyFocusIcon from "../../assets/images/safetyfocus.svg";
import RewardingIcon from "../../assets/images/rewarding.svg";
// import LightTriangle from "../../assets/images/sixer logo 3.svg";
import TriangleIcon from "../../assets/images/sixerlogo1.svg";
import PresaleTokenIcon from "../../assets/images/presaletoken.svg";
import ColouredTriangle from "../../assets/images/colouredtriangle.svg";
const Presale = () => {
  const THREE_DAYS_IN_MS = 21 * 24 * 60 * 60 * 1000;
  // const NOW_IN_MS = new Date().getTime();
  const NOW_IN_MS = 1652353978171;
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  return (
    <>
      <div className="container-fluid presaleWrapper">
        <div className="images">
          <img src={ColouredTriangle} className="images_triangle" />
        </div>

        <div className="presale_wapper">
          <div data-aos="fade-down" data-aos-once="true">
            <h1 className="standouts">
              <span className="ideas">6ixer</span> Strategic Presale Information
            </h1>
          </div>
          <div
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <p className="para">
              Our Presale will be incubated through Yasha DAO’s priority
              Launchpad.
            </p>
          </div>

          <div className="d-flex justify-content-center">
            <a href="https://yashadao.io/yashapad" target="_blank">
              <Button className="connect_btn">
                <img
                  src={PresaleTokenIcon}
                  width="20px"
                  className="walleticon"
                />
                PRESALE LINK
              </Button>
            </a>
          </div>

          <div className=" down_wrapper">
            <Row>
              <Col lg={6} md={12} sm={12} xs={12} className="logo_card">
                <Card className="cards">
                  <div className="card_image">
                    <img src={Logo} width="50%" />
                  </div>
                </Card>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12}>
                <div>
                  <CountdownTimer targetDate={dateTimeAfterThreeDays} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Presale;
